.list-regions {
    li {
      margin: 0.5em 1.2em 0.5em 0;
      display: inline-block;
  
      @media (#{$max-width-tablet}) {
        margin: .2em 0em;
      display: block;
      }
      a {
        font-size: 1.2em;
        @media (#{$max-width-tablet}) {
          font-size: 0.75em;
        }
      }
    }
  }