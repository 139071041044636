// From https://projects.invisionapp.com/share/VYJF6DC63M7#/screens/297183189

$forest: #007145;
$navy: #256b99;
$purple: #75447e;
$teal: #31b7bc;
$green: #41ad4c;
$blue: #267AB5;
$pink: #c994c3;

// from visual design
$light-grey: #f4f4f4;
$mid-grey: #919191;
$dark-mid-grey: #706f70;
$dark-grey: #3b3b3a;

$light-grey-text: #656565;
$light-text: #fff;

$turquoise: #007B7B;
$mid-turquoise: #30b6bb;
$light-turquoise: #83d4d7;

$colourConfigs: (
        base: (
                dark: #007B7B,
                intense: #007B7B,
                mid: #007B7B,
                white: $light-text,
                subtle: #30b6bb,
        ),
        purple: (
                dark: #66356e,
                intense: #75447e,
                mid: #8c5b94,
                white: $light-text,
                subtle: #AB599B,
        ),
        pink: (
                dark: #ae2573,
                intense: #ae2573,
                mid: #c73f8c,
                white: $light-text,
                subtle: #CE3B8E,
        ),
        green: (
                dark: #007145,
                intense: #007145,
                mid: #24966b,
                white: $light-text,
                subtle: #3A8345,
        ),
        blue: (
                dark: $navy,
                intense: $navy,
                mid: $blue,
                white: $light-text,
                subtle: $blue,
        ),
        dark: (
                dark: $dark-grey,
                intense: $dark-grey,
                mid: $blue,
                white: $light-text,
                subtle: $blue
        )
);

