.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 650px;
  margin: 20px auto;

  &__button {
    width: 44px;
    height: 44px;
    min-width: 44px !important;
    padding: 0;
    border-radius: 100% !important;
  }

  &__dots {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0 40px;
    padding: 0;

    @media (#{$max-width-mobile}) {
         margin: 0 20px;
      }
  }

  &__dot {
    &.tns-nav-active {
      button {
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          background-color: $turquoise;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 100%;

          @media (#{$max-width-mobile}) {
            width: 10px;
            height: 10px;
            min-width: 10px !important;
          }
        }
      }
    }
  }

  &__dot-button {
    position: relative;
    width: 32px;
    height: 32px;
    min-width: 32px !important;
    padding: 0;
    border-radius: 100% !important;
    margin: 0 10px;
    background: transparent !important;
    border-width: 3px;

    @media (#{$max-width-mobile}) {
      width: 16px;
      height: 16px;
      min-width: 16px !important;
    }
  }
}
