.region-countries-legend {
  position: relative;
  margin-top: 0.5rem;

  .link-collection {
    border: none;
    margin: 0;
    padding: 0.5rem 0;
  }

  .card {
    font-size: 1em;
  }

  p {
    margin: 0;
  }

  .legend {
    border-right: 1em solid transparent;
    white-space: nowrap;
  }

  & > div {
    margin-bottom: 2em;
  }

  & > div:last-of-type {
    margin-bottom: 0.5em;
  }

  $fractionOfColorWidthAsPadding: 0.25;

  .links {
    padding-left: ((1 + $fractionOfColorWidthAsPadding) * $hit-box-height);
    letter-spacing: 0.3ex;

    a {
      letter-spacing: initial;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .color {
    display: inline-block;
    width: $hit-box-height;
    height: $hit-box-height;
    margin-right: ($fractionOfColorWidthAsPadding * $hit-box-height);
    vertical-align: middle;
  }
}

@media (#{$min-width-tablet}) {
  .region-countries-legend {
    .card {
      font-size: 0.6em;
    }

    .fund-countries, .partner-countries {
      display: table-row;
    }

    .legend, .links {
      display: table-cell;
    }

    .links {
      padding-left: 0;
    }

    & > div {
      margin-bottom: 0.5em;
    }
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName} {
    .region-countries-legend {
      .fund-countries {
        .color {
          background: map_get($colourConfig, intense);
        }
      }

      .partner-countries {
        .color {
          background: map_get($colourConfig, subtle);
        }
      }

      .links {
        color: map_get($colourConfig, intense);
      }
    }
  }
}