.structure {
  position: relative; // to make div.section-id work; may not be needed eventually
  padding: 1em;
  margin-bottom: 1em;
}

.box {
  @extend .structure;
  background: #e0e0e0;
}

div.footer {
  margin-top: 2em;
}

div.section-id {
  position: absolute;
  color: red;
  top: 0.25em;
  right: 0.5em;
}
