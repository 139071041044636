.activity-types-filter {
  margin: 0 0 1em;
  position: relative;
  top: 1em;

  .show-me {
    color: $turquoise;
    padding-right: 0.5em;
  }

  ul {
    display: inline-block;
    @include pipe_horizontal_ul;

    li {
      font-size: 19px;

      &:before {
        color: $turquoise;
      }

      &.active {
        color: $turquoise;
      }
    }
  }
}

.activity-cards-container {
  .row-title {
    margin-top: 1rem;
  }
}
