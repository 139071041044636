.centered-image-and-text {
  margin: 20px auto;
  max-width: 80%;
  text-align: center;

  @media (#{$max-width-tablet}) {
    max-width: 100%;
  }

  &__img {
    max-width: 100%;
  }

  &__text {
    margin: 0;
  }
}