// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-larger-than-mobile    : "min-width: 400px";
$bp-larger-than-phablet   : "min-width: 550px";
$bp-larger-than-tablet    : "min-width: 750px";
$bp-larger-than-desktop   : "min-width: 1000px";
$bp-larger-than-desktophd : "min-width: 1200px";

$breakpoint-mobile: 500px;
$breakpoint-tablet: 750px;
$breakpoint-desktop: 1000px;
$max-width-mobile: 'max-width:' + $breakpoint-mobile;
$min-width-mobile: 'min-width:' + ($breakpoint-mobile + 1px);
$max-width-tablet: 'max-width:' + $breakpoint-tablet;
$min-width-tablet: 'min-width:' + ($breakpoint-tablet + 1px);
$max-width-desktop: 'max-width:' + $breakpoint-desktop;
$min-width-desktop: 'min-width:' + ($breakpoint-desktop + 1px);

// Colors
$light-grey: #e1e1e1;
$dark-grey: #333;
$primary-color: #33c3f0;
$secondary-color: lighten($dark-grey, 13.5%);
$border-color: #bbb;
$link-color: #1eaedb;
$font-color: #222;

// Typography
// skeleton's default: $font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
// our default, except Helvetica Neue Regular doesn't look like Ben's slides for me => use Arial for now
// "Helvetica Neue Regular", Helvetica Neue, Helvetica, Arial, sans-serif;
$font-family: Arial, sans-serif;

//Grid Variables
$container-width: 1050px;
$container-width-larger-than-mobile: 90%;
$container-width-larger-than-phablet: 90%;
$total-columns: 12;
$column-width: calc(100 / $total-columns); // calculates individual column width based off of # of columns
$column-margin: 4%; // space between columns

// Misc
$global-radius: 4px;
