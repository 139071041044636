$global-nav-background-color: $dark-grey;
$sub-global-nav-background-color: $light-turquoise;

nav.global-nav {
  background-color: $global-nav-background-color;
  color: $light-text;

  ul {
    @include pipe_horizontal_ul;
    width: 100%;

    li {
      font-size: 19px;
      line-height: 1.4em;
      position: relative;
      vertical-align: middle;

      a {
        position: relative;
        background-color: $global-nav-background-color;

        &:hover, &:focus {
          background: rgba($light-grey, 0.1);
          outline: none;
          border: initial;
          text-decoration: underline;
        }
      }

      &.active a:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        border: 0.5em solid transparent;
        border-bottom-color: $sub-global-nav-background-color;
        transform: translateX(-50%);
      }
    }
  }

  .mobile-toggle-menu {
    display: none;
    padding: 0.7em 0;
    color: $light-grey;

    &:hover, &:focus {
      background: rgba($light-grey, 0.1);
      outline: none;
      border: initial;
    }
  }

  .mobile-toggle-submenu {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: $hit-box-height;
    text-align: center;
    cursor: pointer;
  }

  .mobile-submenu {
    box-sizing: border-box;
    display: none;
    padding-left: 1rem;
    border-bottom: 1px solid rgba($light-grey, 0.4);

    li {
      border: none;
      font-size: 18px;
      line-height: 1.2em;

      a {
        padding: 0.4em 0;

        &:not(.mobile-toggle-submenu):before {
          content: "•";
          padding: 0 0.5em 0 0;
        }
      }
    }
  }

  .tablet-toggle-more {
    display: none;
  }
}

nav.sub-global-nav {
  ul {
    @include pipe_horizontal_ul;
    width: 100%;
  }

  background-color: $sub-global-nav-background-color;
  color: $dark-grey;
  min-height: 4px;

  li {
    font-size: 16px;
    a {
      &:hover, &:focus {
        background: rgba($dark-grey, 0.1);
        outline: none;
        border: initial;
        text-decoration: underline;
      }
    }
  }

  .tablet-toggle-more {
    display: none;
  }
}

@media (#{$max-width-tablet}) and (#{$min-width-mobile}) {
  nav.global-nav {
    ul {
      li {
        font-size: 15px;
      }
    }
  }
  nav.sub-global-nav {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}

@media (#{$max-width-desktop}) and (#{$min-width-mobile}) {
  nav.global-nav, nav.sub-global-nav {
    ul {
      li {
        &.tablet-hide {
          display: block;
          width: 100%;

          &:before {
            display: none !important;
          }

          a {
            width: 100%;
          }
        }

        &.active a:after {
          display: none;
        }
      }
    }

    &.tablet-more-hidden {
      ul {
        li {
          &.tablet-hide {
            display: none;
          }

          &.active a:after {
            display: initial;
          }
        }
      }
    }

    .tablet-toggle-more {
      display: initial;
    }
  }
}

@media (#{$max-width-mobile}) {
  nav.global-nav {
    ul {
      li {
        width: 100%;
        border-top: 1px solid rgba($light-grey, 0.4);

        &:before {
          display: none !important;
        }

        a {
          display: block;
          overflow: hidden;
        }

        &.active a:after {
          display: none;
        }

        &.mobile-submenu-showing {
          & > a {
            border-bottom: 0.15em solid $turquoise;
            font-weight: bold;
          }

          & > .mobile-submenu {
            display: block;
          }
        }
      }
    }

    &.mobile-hidden ul {
      display: none;
    }

    .mobile-toggle-menu {
      display: block;
    }

    .mobile-toggle-submenu {
      display: block;
    }
  }

  nav.sub-global-nav * {
    display: none;
  }
}

div.breadcrumbs {
  ul {
    @include horizontal_ul;
  }

  margin: 1.5em 0 1em;
  color: $light-grey-text;

  svg {
    fill: $light-grey-text;
    width: 15px;
  }

  li {
    font-size: 16px;

    &:not(:first-child) {
      @include has_left_rightward_arrow;
    }
  }
}

@media(#{$max-width-mobile}) {
  div.breadcrumbs {
    li {
      $breadcrumb_li_on_mobile_selector: ":nth-last-child(2)";

      &#{$breadcrumb_li_on_mobile_selector} {
        &:before {
          display: none;
        }

        a {
          @include has_left_leftward_arrow;

          &:before {
            margin-left: 0;
          }
        }
      }

      &:not(#{$breadcrumb_li_on_mobile_selector}) {
        display: none;
      }
    }
  }
}

ul.horizontal-pipe {
  @include pipe_horizontal_ul;
}