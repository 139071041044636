// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1, .h1-font { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;  }
h2, .h2-font { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem;  }
h3, .h3-font { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem;  }
h4, .h4-font { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
h5, .h5-font { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6, .h6-font { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0;       }

// Larger than phablet
@media (#{$bp-larger-than-phablet}) {
  h1, .h1-font { font-size: 5.0rem; }
  h2, .h2-font { font-size: 4.2rem; }
  h3, .h3-font { font-size: 3.6rem; }
  h4, .h4-font { font-size: 3.0rem; }
  h5, .h5-font { font-size: 2.4rem; }
  h6, .h6-font { font-size: 1.5rem; }
}

p {
  margin-top: 0;
}
