@mixin has_left_rightward_arrow {
  &:before {
    margin-left: 1ex;
    margin-right: 1ex;
    content: '›';
    position: relative;
    top: -0.2ex;
  }
}

@mixin has_left_leftward_arrow {
  &:before {
    margin-left: 1ex;
    margin-right: 1ex;
    content: '‹';
  }
}

@mixin horizontal_ul {
  margin: 0;
  padding: 0;
  font-size: 0;
  display: inline-block;

  &.all-but-first-in-submenu li:nth-child(2) {
    @include has_left_rightward_arrow;
  }

  li {
    list-style-type: none;
    display: inline-block;
    margin: 0;

    a {
      font: inherit;
      color: inherit;
      text-decoration: none;
      display: inline-block;
      padding: 0.55em 0;

      img, svg {
        height: 12px;
        padding: 0 0.7ex;
      }
    }
  }
}

@mixin pipe_horizontal_ul($pipe-height: 0.8em, $pipe-vertical-align: unset) {
  @include horizontal_ul;

  &.all-but-first-in-submenu li:nth-child(n+3):before,
  &:not(.all-but-first-in-submenu) li:not(:first-child):before {
    margin: 0.2em 1ex 0;
    height: $pipe-height;
    width: 1px;
    background: currentColor;
    opacity: 0.45;
    content: ' ';
    display: inline-block;
    vertical-align: $pipe-vertical-align;
  }
}