.document-group{
    display: flex;
    gap: 30px;
    flex-flow: row wrap;

    &__column{
        flex: 0 0 calc(50% - 30px);
        @media (#{$max-width-mobile}) {
            flex: 0 0 100%;

        }
    }
}