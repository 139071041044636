@mixin cardsAreCentered {
  text-align: center;
  justify-content: center;

  .card {
    text-align: initial;
  }
}

@mixin wrapsUnderneathAside {
  clear: both;
}