.filter-dropdowns-container {
  position: relative;
  white-space: nowrap;

  .filters-heading {
    color: $turquoise;
    font-size: 0.9em;
    font-weight: 500;
    margin-bottom: 1.1em;
  }

  #filter-search-form {
    display: flex;
    flex-direction: column;

    .filter-fields-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .apply-filter-button {
      width: 100px;
      color: #fff;
      font-size: 0.8em;
      padding: 0 10px;
      height: 40px;
      &:hover,
      &:focus {
        color: $dark-grey;
        outline: 2px solid $dark-grey;
      }
      &:focus {
        outline: 2px solid $dark-grey;
      }
    }
  }

  .clear-all-button {
    position: absolute;
    bottom: 10px;
    left: 120px;
    input {
      padding: 0;
      margin: 0;
      height: 40px;
      font-size: 0.8em;
      padding: 0 10px;
      width: 120px;
      border: 2px solid $dark-grey;
      &:hover,
      &:focus {
        background-color: $dark-grey;
        color: #fff;
      }
    }
  }

  .filter-dropdown-group {
    label {
      color: $turquoise;
      font-size: 1em;
      font-weight: 500;
      margin-bottom: 1.1em;
    }
  }

  .filter-dropdown {
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
  }

  &.four-filters {
    .filter-dropdown-group {
      @include perRow(4);
    }
  }

  &.three-filters {
    .filter-dropdown-group {
      @include perRow(3);
    }
  }

  &.two-filters {
    .filter-dropdown-group {
      @include perRow(2);
    }
  }

  @media (#{$max-width-tablet}) {
    .filter-dropdown-group {
      @include perRow(2);
    }
  }
  @media (#{$max-width-mobile}) {
    .filter-dropdown-group {
      @include perRow(1);
      label {
        margin-bottom: 10px;
      }
    }

    &.two-filters,
    &.three-filters {
      .filter-dropdown-group {
        @include perRow(1);
      }
    }

    .clear-all-button {
      position: relative;
      bottom: 0;
      left: 0;
      input {
        width: 100%;
      }
    }
    #filter-search-form {
      .apply-filter-button {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
