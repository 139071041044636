@mixin grant_type_label_style($font-size, $line-height) {
  display: inline-block;
  width: 60%;
  font-size: $font-size;
  line-height: $line-height;
  vertical-align: middle;
}

@mixin number_of_grants_style($font-size) {
  display: inline-block;
  width: 35%;
  font-weight: bold;
  font-size: $font-size;
  text-align: center;
  vertical-align: middle;
  margin: 18px 0;
}

.grant-number-boxes {
  color: $dark-grey;
  margin-bottom: 10px;

  .grant-number-box {
    background: $mid-turquoise;
    margin-bottom: 30px;

    &.four {
      .number-of-grants {
        @include number_of_grants_style(3em);
      }

      .grant-type-label {
        @include grant_type_label_style(1.5em, 1.2em);
      }
    }

    &.three.narrow-margins {
      @media (min-width: 1100px) {
        width: 23.5%;
        margin-left: 2%;
        &.columns:first-child {
          margin-left: 0%;
        }
      }

      @media ($min-width-mobile) and (max-width:1100px) {
        width: 49%;
        margin-left: 2%;
        &.columns:nth-child(odd) {
          margin-left: 0%;
        }
      }

      .number-of-grants {
        @include number_of_grants_style(2.7em);
      }

      .grant-type-label {
        @include grant_type_label_style(1.2em, 1.2em);
      }
    }

    .button {
      display: block;
      background: $light-turquoise;
      text-align: left;
      text-indent: 35%;
      margin: 0;
      border: none;
      color: $dark-grey;
      
      &::after {
        content:">";
        padding-left: 1em;
        font-weight: 900;
      }
    }
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName} .country.grant-number-boxes .grant-number-box {
    border-color: map-get($colourConfig, intense);
    background: map-get($colourConfig, intense);
    color:$light-text;

    .button {
      background: map-get($colourConfig, subtle);
      color:$light-text;
    }
  }
}

@media (#{$max-width-tablet}) {
  .grant-number-boxes .grant-number-box .number-of-grants {
    margin-top: 0;
    margin-bottom: 0;
  }
}
