hr.whitespace-above {
  margin-top: 2em;
}
hr.whitespace-below {
  margin-bottom: 2em;
}
hr.whitespace-both {
  margin-top: 2em;
  margin-bottom: 2em;
}
