header {
  font-size: 0;

  .logo-container, .search-container {
    display: inline-block;
    width: 50%;
  }

  .logo-container {
    margin: 15px 0;
    vertical-align: middle;
  }

  .logo {
    font-size: 0;
    display: inline-block;
    max-width: 100%;

    svg {
      width: 225px;
      max-width: 100%;
    }
  }

  .mobile-toggle-search {
    display: none;
  }

  .search-container {
    text-align: right;
    font-size: 1.6rem;
    vertical-align: bottom;
    margin-bottom: 10px;
  }

  .search {
    display: block;
    margin: 0;
    padding-right: $hit-box-height;
    position: relative;
    box-sizing: border-box;
  }

  .input-container {
    display: block;
    width: auto;
    overflow: hidden;
    text-align: right;

    input {
      display: inline-block;
      width: 100%;
      max-width: 250px;
      background: $light-grey;
      color: $dark-grey;
      border: none;
      height: $hit-box-height;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  button {
    display: block;
    background: $turquoise;
    margin: 0;
    float: right;

    svg {
      fill: #fff;
    }

    &.perform-search {
      position: absolute;
      top: 0;
      right: 0;

      &:focus{
        outline: 3px solid $dark-grey;
      }
    }
  }

  .lightweight-site-link {
    margin-top: 5px;
    white-space: nowrap;
    color: $dark-grey;
  }
}

@media (#{$max-width-mobile}) {
  header {
    .mobile-toggle-search {
      display: inline-block;
      width: 50%;
      vertical-align: middle;
    }

    .search-container {
      text-align: center;
      width: 100%;
    }

    .search {
      &.mobile-hidden {
        display: none;
      }
    }

    .input-container {
      input {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
    }
  }
}