.spinner {
    display: none;
    width: 80px;
    height: 80px;
    margin: 40px auto;
    &:after {
        content: "";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $turquoise;
        border-color: $turquoise transparent $turquoise transparent;
        animation: spinner 1.2s linear infinite;
      }

      &--show{
        display: block;
      }
  }
 
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  