.list-home-splash {
    li {
      margin: 0.5em 0.8em 0.5em 0;
      display: inline-block;
  
      @media (#{$max-width-tablet}) {
        margin: 0.1em 0;
      display: block;
      }
      a {
        font-size: 0.8em;
        @media (#{$max-width-tablet}) {
          font-size: 0.6em;
        }
      }
    }
  }