footer {
  font-size: 0;
  width: 100%;
  overflow: hidden;
  margin: 6rem 0 6rem;

  p, li {
    font-size: 16px;
    margin: 0;
    color: $turquoise;
  }

  .footer-content {
    border-top: 1px solid $turquoise;
    margin: 1rem 0 3rem;
    padding-top: 1rem;
  }

  ul {
    white-space: nowrap;
  }

  .left, .right {
    width: 100%;
  }

  .social-links {
    @include pipe_horizontal_ul(34px, top);

    margin-bottom: 2em;
    display: inline-block;
    vertical-align: middle;

    li {
      color: $dark-grey;
      
      &::before {
        background: $mid-grey !important;
      }

      a {
        img {
          height: 20px;
        }
      }
    }
  }

  .copyright {
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 0.3em;
    font-size: 14px;
  }

  .about-links {
    @include pipe_horizontal_ul;

    margin-top: 1em;
    margin-bottom: 2em;
    display: inline-block;
    vertical-align: middle;

    li {
      color: $dark-grey;
      font-size: 14px;

      a {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .forced-break {
      display: none;
    }
  }

  .left {
    .footer-content {
      p {
        font-size: 0;
      }

      // was a, but we've removed the links for now
      &__partners{
        white-space: normal;
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 25px 50px 0 0;
          width: 160px;
        }

      }
      img {
        max-width: 160px;
        max-height: 90px;
        width: auto;
        height: auto;
      }
    }
  }

  .right {
    display: inline-block;
    vertical-align: middle;

    .footer-content {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media (#{$min-width-desktop}) {
  footer {
    .left {
      width: grid-column-width(8);
      display: inline-block;
    }
    .right {
      width: grid-column-width(4);
      margin-left: $column-margin;
      vertical-align: top;
    }

    .about-links {
      .forced-break {
        display: block;
      }

      li:last-of-type:before {
        display: none !important;
      }
    }
  }
}