@import "cards-functions";

.card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  display: block;
}

.card {
  // Internal layout

  $card-padding: 1.5em;

  display: inline-flex;
  flex-flow: column nowrap;
  color: inherit;
  font: inherit;
  text-decoration: initial;
  overflow: hidden;
  border-radius: 1px;
  font-size: (0.8 * $base-font-size);

  a,
  button {
    position: relative;
    z-index: 2;
  }

  a.card-cta-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    max-width: 100%;
  }

  button,
  .button {
    margin: (0.8 * $card-padding) 0 0;
    font-size: 1.2em;
    min-width: 30%;
  }

  h3,
  .h3-font {
    font-size: 2.2em;
    margin: (0.2 * $card-padding) 0;

    a {
      color: inherit;
    }
  }

  p {
    width: 100%;
    margin: 0;
    font-size: 1.2em;
  }

  a:not(.button):hover {
    text-decoration: underline;
  }
  .header {
    padding: (0.5 * $card-padding) $card-padding $card-padding;
    border-bottom: 0.3ex solid #fff;

    p {
      font-size: 1.35em;

      &.identifier {
        font-size: 1em;
      }
    }
  }

  .content {
    padding: 0 $card-padding $card-padding;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    p {
      margin-top: (0.5 * $card-padding);

      &:last-of-type {
        flex: 1 1 auto;
      }
    }
  }

  .identifier {
    margin: 0;
    width: 100%;
    padding-bottom: (0.5 * $card-padding);
    border-bottom: 0.2ex solid $light-grey;
    font-size: 1em;
  }

  &.no-content {
    .header {
      flex: 1 0 auto;
    }
  }

  // Map colours

  &.decorated {
    background: $light-grey;
    border-top: 8px solid $light-grey;

    @each $configName, $colourConfig in $colourConfigs {
      &.#{"" + $configName} {
        border-top-color: map-get($colourConfig, subtle);
        .header {
          color: map-get($colourConfig, intense);

          a:hover {
            text-decoration: underline;
          }
        }

        .identifier {
          border-bottom-color: map-get($colourConfig, subtle);
        }

        .content {
          color: $dark-grey;
        }

        &.inverted-header {
          .header {
            background: map-get($colourConfig, intense);
            color: #fff;
          }
        }
      }
    }
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .aside-card.#{"" + $configName} {
    border-color: map-get($colourConfig, intense);
    background: map-get($colourConfig, intense);
    color: #fff;
    width: 100%;

    .button {
      background: map-get($colourConfig, subtle);
    }
  }
}
// Layout

@mixin perRow($numPerRow) {
  @if ($numPerRow == 1) {
    width: 100%;
    margin-right: 0 !important;
  } @else {
    width: grid-column-width(calc(12 / $numPerRow));
    margin-right: calc(($numPerRow - 1) * $column-margin / $numPerRow);
    &:nth-of-type(#{$numPerRow}n) {
      margin-right: 0;
    }
    &:not(:nth-of-type(#{$numPerRow}n)) {
      margin-right: $column-margin;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Used in news-events page that can have a mix of half and full width cards.
@mixin maxPerRow($numPerRow) {
  margin-right: -2%;
  margin-left: -2%;
  & > .card {
    margin-right: calc($column-margin/2);
    margin-left: calc($column-margin/2);
    width: calc($column-width * $total-columns / $numPerRow) - $column-margin;
  }
}

.card-container {
  display: flex;
  flex-flow: row wrap;
  @include wrapsUnderneathAside;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .card {
    @media (#{$min-width-desktop}) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    margin-top: calc($column-margin / 2);
    margin-bottom: calc($column-margin / 2);
  }

  &.cover .card {
    @include perRow(1);
  }
  &.new-two-max {
    @include maxPerRow(2);
  }
  &.new-four-max {
    @include maxPerRow(4);
  }
  &.two-max .card {
    @include perRow(2);
  }
  &.three-max .card {
    @include perRow(3);
  }
  &.four-max .card {
    @include perRow(4);
  }
  &.four-max-desktop-one-max-mobile .card {
    @include perRow(4);
  }
  &--opacity {
    opacity: 0.5;
  }
  &--carousel {
    .card.with-image {
      border-top: 8px solid $turquoise;
    }
  }
}

@media (#{$max-width-desktop}) {
  .card {
    font-size: 14px;
  }
  .card-container {
    &.four-max-desktop-one-max-mobile .card {
      @include perRow(3);
    }
  }
}

@media (#{$max-width-tablet}) {
  .card {
    font-size: 13px;
  }

  .card-container {
    &.new-two-max {
      @include maxPerRow(2);
    }
    &.new-four-max {
      @include maxPerRow(2);
    }
    &.two-max .card {
      @include perRow(2);
    }
    &.three-max .card {
      @include perRow(2);
    }
    &.four-max .card {
      @include perRow(2);
    }
    &.four-max-desktop-one-max-mobile .card {
      @include perRow(2);
    }
    &.aside-desktop-main-flow-mobile .card {
      @include perRow(2);
    }
  }
}

@media (#{$max-width-mobile}) {
  .card {
    font-size: 13px;
  }

  .card-container {
    &.new-two-max {
      @include maxPerRow(1);
    }
    &.new-four-max {
      @include maxPerRow(2);
    }
    &.two-max .card {
      @include perRow(1);
    }
    &.three-max .card {
      @include perRow(1);
    }
    &.four-max .card {
      @include perRow(2);
    }
    &.four-max-desktop-one-max-mobile .card {
      @include perRow(1);
    }
    &.aside-desktop-main-flow-mobile .card {
      @include perRow(1);
    }
  }
}

// Special types

@import "cards-special";
