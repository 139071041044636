button, .button {
  box-sizing: content-box;
  padding: 0.75em 1.25em;
  font-size: inherit;
  line-height: 1em;
  height: 1em;
  white-space: nowrap;
  text-transform: none;
  font-weight: normal;
  background: $dark-grey;
  color: #fff;

  &:hover, &:focus {
    opacity: 1;
  }

  &.hollow {
    background: transparent;
    border: 1px solid $dark-grey;
    color: $dark-grey;
  }

  &:not(.square-icon) {
    min-width: 6em;
  }

  &.square-icon {
    $padding: 10px;
    box-sizing: border-box;
    width: $hit-box-height;
    height: $hit-box-height;
    padding: $padding;
    border: none;

    svg {
      width: ($hit-box-height - 2*$padding);
      height: ($hit-box-height - 2*$padding);
    }
  }

  &.load-more {
    background: $turquoise;
    border-color: $turquoise;
    display: block;
    width: 8em;
    margin: 0 auto;
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName},
  .card.#{"" + $configName} // cards should override
  {
    button,
    .button
    {
      border-color: map-get($colourConfig, intense);
      background: map-get($colourConfig, intense);

      &:hover, &:focus {
        border-color: map-get($colourConfig, subtle);
        background: map-get($colourConfig, subtle);
      }

      &.hollow {
        color: map-get($colourConfig, intense);
        background: transparent;

        &:hover, &:focus {
          color: map-get($colourConfig,  white);
          background:  map-get($colourConfig, intense);
         
        }
      }
    }
  }
}

button, .button {
  &:disabled, &.disabled {
    background: $mid-grey;
    border-color: $mid-grey;
    color: #fff;
    cursor: pointer;
  }
}
