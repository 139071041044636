
#map-element {
  height: 200px;
  height: 30vh;
  display: none;

  g {
    transition: transform 0.5s ease-in-out;
  }

  .map-container {
    height: 100%;
    box-sizing: border-box;
    max-height: none;
  }
}
@media (prefers-reduced-motion: reduce) {
  #map-element {
    g {
      transition: none;
    }
  }
}

#region-cards {
  clear: none;

  .card {
    background: #fff;
    box-shadow: 0 0 7px rgba(0,0,0,0.2);
    margin-bottom: 40px;
    height: fit-content; 

    .header {
      padding-bottom: 0;
    }

    .content-block {
      width: 100%;
      padding-top: 1em;
      margin-bottom: 1em;

      &:first-of-type {
        margin-top: 0;
      }
    }

    p {
      margin: 0;
    }

    .legend-label {
      margin-bottom: 0.5em;
    }

    .legend-items {
      margin-bottom: 2em;
      letter-spacing: 0.3ex;

      &:last-of-type {
        margin-bottom: 0.5em;
      }

      a {
        letter-spacing: initial;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .fund-color, .partner-color {
      display: inline-block;
      width: $hit-box-height;
      height: $hit-box-height;
      vertical-align: middle;
      margin-right: 0.5ex;
    }

    .grant-statistics {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 0.5em;

      .statistic-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .grant-number {
          font-size: 2.4em;
          font-weight: bold;
        }

        .grant-label {
          font-size: 1.2em;
          text-align: center;
        }
      }
    }

    .regional-activity-button {
      @extend .button;
      margin-top: 0;
      border: 0;
      white-space: normal;
      min-height: 1em;
      height: auto;

      &::after {
        content: ">";
        padding-left: 1em;
        font-weight: 900;
      }
    }

    @each $configName, $colourConfig in $colourConfigs {
      &.#{"" + $configName} {
        .content-block, .grant-statistics {
          border-top: 1px solid map_get($colourConfig, intense);
        }

        .grant-statistics .statistic-item {
          color: map_get($colourConfig, mid);
        }

        .fund-color {
          background: map_get($colourConfig, intense);
        }

        .partner-color, .regional-activity-button {
          background: map_get($colourConfig, subtle);
          &:hover{
            color: $light-text;
          }
        }

        a:not(.regional-activity-button), .legend-items {
          color: map_get($colourConfig, intense);
        }

        h3 a:hover, .h3-font a:hover, .grant-statistics .statistic-item:hover {
          color: map_get($colourConfig, subtle);
        }

        .header h3 a, .header .h3-font a {
          color: $dark-grey;
        } 
      }
    }

    &.blue .grant-statistics .statistic-item {
      color: map_get(map_get($colourConfigs, blue), intense);
    }
  }
}

@media (#{$max-width-tablet}) {
  #map-element {
    position: relative !important;
    height: 200px !important;
    height: 30vh !important;
  }

  #region-cards .grant-statistics {
    justify-content: space-around;
  }
}

@media (#{$min-width-tablet}) {
  #region-cards {
    padding-bottom: 200px;
    padding-bottom: 25vh;
    margin-top: 20px;
    margin-top: 3vh;

    .card {
      min-height: 33vh;
      transition: opacity 0.2s linear;

      &.inactive {
        opacity: 1;
      }
    }
  }

  body {
    &.light #region-cards .grant-statistics {
      justify-content: space-around;
    }

    #region-cards .grant-statistics {
      justify-content: space-between;
    }
  }

  #map-element {
    width: 100%;
    height: 400px;
    height: 100vh;
    position: relative;


    &.top {
      position: absolute;
      top: 0;
      left: 0;
      bottom: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
    }
    &.bottom {
      position: absolute;
      top: auto;
      left: 0;
      bottom: 0;
    }
  }
}

#region-cards .statistics .card {
  margin: 0;
  padding: 0;
  box-shadow: none;
  min-height: 0;
  text-align: left;
}