.home-splash {
  background: $turquoise;
  padding: 5rem 0;
  margin: 0;

  p.large-text {
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    font-family: $font-family-headings;
    margin: 0.5em 0;
  }

  .link-collection {
    color: #fff;
    margin-bottom: 0;
    font-size: 1.5em;
    border: none;

    // More-specific a: this is getting overridden by '.base card a'
    a {
      color: #fff;
      &:hover{
        color: #fff;
      }
    }
  }
}

.home-splash.without-opportunities {
  margin-bottom: 2rem;
}

.heavy .home-splash {
  background-image: url(/images/home-header-image.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media  (#{$max-width-desktop}) {
  .home-splash {
    p.large-text {
      font-size: 30px;
    }
  }
}

@media  (#{$max-width-tablet}) {
  .home-splash {
    p.large-text {
      font-size: 25px;
    }
  }
}

@media (#{$max-width-mobile}) {
  .home-splash {
    p.large-text {
      font-size: 20px;
    }
  }
}