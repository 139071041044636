// Fallbacks from https://graphicdesign.stackexchange.com/a/17598/5490
$font-family-headings: "proxima-nova", sans-serif;
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$base-font-size: 18px;

body {
  font-size: $base-font-size;
  line-height: 1.62;
  font-family: $font-family;
  font-weight: normal;
}

.large-text {
  font-size: 1.3em;
}

.overview-text {
  @extend .large-text;
  font-weight: 500;
}

h1, h2, h3, h4, h5 {
  font-family: $font-family-headings;
  font-weight: bold;
}

h1, .h1-font {
  font-size: 50px;
}

h2, .h2-font {
  font-size: 40px;
}

h3, .h3-font {
  font-size: 32px;
}

@media (#{$max-width-tablet}) {
  body {
    font-size: 18px;
  }

  h1, .h1-font {
    font-size: 43px;
  }

  h2, .h2-font {
    font-size: 32px;
  }

  h3, .h3-font {
    font-size: 24px;
  }
}

@media (#{$max-width-mobile}) {
  h1, .h1-font {
    font-size: 36px;
  }

  h2, .h2-font {
    font-size: 28px;
  }

  h3, .h3-font {
    font-size: 20px;
  }
}
