.prominent-button {
  margin-top: 1em;
  margin-bottom: 1em;

  &.centred {
    text-align: center;
  }

  button, .button {
    white-space: normal;
    height: auto;
  }

  .turquoise, .turquoise:hover, .turquoise:focus {
    background-color: $turquoise;
    border-color: $turquoise;
  }
}