.application-steps-heading {
  @extend .large-text;

  .application-steps-heading-prefix {
    font-weight: 500;
  }
}

.application-steps-container.collapsible {
  background: none;
  border: 1px solid $turquoise;
  border-top: none;
  padding: 3em;
}

.application-step-heading {
  font-weight: bold;
}

.application-step {
  padding: 1.5em 2em;
  background: $light-grey;
  position: relative;

  p {
    margin: 0.5em 0;
  }

  &:not(:last-child) {
    margin-bottom: 3em;

    &:after {
      content: "";
      height: 0;
      width: 0;
      border: 2em solid transparent;
      border-top-color: $light-grey;
      position: absolute;
      bottom: -4em;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:last-child {
    font-weight: bold;
    padding-left: 5em;
    border: 1px solid #009898;
  }
}

.application-step-tick {
  height: 2em;
  margin: 0;
  position: absolute;
  top: calc(50% - 1em);
  left: 1.5em;
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName} {
    .light-step {
      color: map-get($colourConfig, intense);
    }

    .application-step-tick {
      fill: map-get($colourConfig, intense);
    }
  }
}

@media (#{$max-width-tablet}) {
  .application-step-heading {
    text-align: center;
  }
}

@media (#{$max-width-mobile}) {
  .application-steps-container.collapsible {
    padding: 1.5em;
  }
}
