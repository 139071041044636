.carousel-cards {
  &__card {
    .card {
      &.decorated {
        &.base {
          border-top-color: $turquoise;
          .identifier{
            border-bottom-color: $turquoise;
          }
        }
      }
      .header {
        border: 0;
      }
    }
  }
}
