.download-info{
    display: block;
    font-size: 16px;
   
    &--box-aside{
        margin-top: 10px;
    }
    &__type{
        text-transform: uppercase;
        font-weight: 700;
    }
}