.pagination-links {
  margin: 3rem 0 5rem;
  border-top: 1px solid $turquoise;
  font-size: 1.1em;

  .page-numbers {
    display: inline-block;
    vertical-align: top;
    margin: 0 0.5em;
  }

  .current {
    font-weight: bold;
    color: $turquoise;
  }
}