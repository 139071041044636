.card.with-image {
  border: none;

  // By default display the image above the rest of the card
  .image-container {
    width: 100%;
    max-height: 800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    &.lock-aspect-ratio {
      max-height: 100%;
      img {
        object-fit: contain;
      }
    }
  }

  // Inner 'card' contains the title and description
  .card {
    border: none;
    margin: 0;
    width: 100%;

    .header {
      border-bottom: none;
    }
  }

  &.image-left, &.image-right {
    width: 100%;
  }
}

@media (#{$min-width-desktop}) {
  .card.with-image.image-left, .card.with-image.image-right {
    font-size: 0;
    display: flex;
    flex-flow: row;
    min-height: 350px;

    .card {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 50%;
      padding-top: 1rem;
      padding-left: (0.5 * $column-margin);
      margin: 0;
    }

    .image-container {
      position: relative;
      vertical-align: top;
      min-height: 100%;
      width: 50%;
      margin: 0;

      img {
        position: absolute;
        height: 100%;
        // Old FF versions + IE (centre image)
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: transform .5s ease;
        @media (prefers-reduced-motion) {
          transition: none;
        }
      }

      &.lock-aspect-ratio {
        max-height: none;
        img {
          max-height: 800px;
        }
      }
    }

    &:hover {
      .image-container {
        img {
          -webkit-transform: translate(-50%, -50%) scale(1.2);
          transform: translate(-50%, -50%) scale(1.2);
        }
      }
    }
  }
  .card.with-image.image-right {
    .image-container {
      order: 1;
    }
  }
}

.card-container.statistics {
  @include cardsAreCentered;

  .card {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    overflow: visible;

    .statistic-value {
      font-size: 3em;
      font-weight: bold;
      width: 100%;
    }

    .statistic-text {
      width: 100%;
      margin: 0;
      font-size: 1.2em;
    }
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName} {
    .card-container.statistics {
      .card .statistic-value {
        color: map_get($colourConfig, intense);
      }
    }
  }
}

.card-container.opportunities {
  align-items: center;
  margin-bottom: 5px;
  .card {
    box-sizing: border-box;
    font-weight: bold;
    font-size: 0.8em;

    .status {
      font-weight: normal;
    }
  }

  .opportunity {
    border: 1px solid $turquoise;
    padding: 0.5em 1em;
    align-self: stretch;
  }

  .apply-button {
    margin-left: auto;
    button, .button {
      margin: 0;
      padding: 0.7em 0.5ex;
      min-width: 0;
      max-width: 100%;
      height: auto;
      font-weight: 500;
    }
  }
}

.card-container.people, .card-container.organisations {
  .card {
    .header {
      padding-bottom: 0;
    }
    h3, .h3-font {
      font-size: 1.3em;
    }
    .image-container {
      height: 150px;
      position: relative;
    }
    img {
      max-width: 100%;
      max-height: 120px;
      width: auto;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 10px;
    }
    .identifier {
      margin-top: 0.5ex;
    }
    p {
      margin-top: 0;
      font-size: 1em;
    }
  }
}

.card-container.people {
  .card {
    img {
      border-radius: 50%;
    }
  }
}

.card-container.link-collection {
  border: 0 solid $turquoise;
  border-width: 1px 0;
  color: $turquoise;
  margin: 0.5em 0 2em;

  .card {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    .header {
      padding: 0;
      border: none;

      p {
        margin: 0;
        font-size: 1em;
      }
    }

    .content {
      display: none;
    }
  }

  a:hover {
    color: $dark-grey;
  }
}

@media (#{$min-width-mobile}) {
  .card-container.link-collection {
    .card {
      .header {
        font-size: 1.2em;
      }
    }
  }
}

@media (#{$min-width-tablet}) {
  .card-container.link-collection {
    justify-content: flex-start;
    border-width: 0 0 1px;
    padding-bottom: 1ex;
    margin-top: 0;

    .card {
      display: inline-block;
      width: auto;
      margin: 0.5em 2em 0.5em 0 !important;

      .header {
        font-size: 1.5em;
      }
    }
  }
}

@each $configName, $colourConfig in $colourConfigs {
  .#{"" + $configName} {
    .card-container.link-collection {
      border-color: map_get($colourConfig, intense);

      .card {
        color: map_get($colourConfig, intense);
      }
    }
  }
}

.card-container.useful-links {
  .card {
    .header {
      font-size: 0.8em;
    }

    h3, .h3-font {
      margin-top: 0.8em;
      margin-bottom: 0.8em;
    }
  }
}

.card-container.youtube {
  .card {
    box-sizing: content-box;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto !important;

    .content {
      padding-top: 1.5em;
    }

    iframe {
      max-width: 100%;
    }
  }
}
