.quote {
    border-left: 0.6em solid $turquoise;
    padding: 0.2em 0 0.2em 2em;
    position: relative;

    &:before {
        content: '"';
        line-height: 0.8em;
        position: absolute;
        top: 0;
        left: 0.1em;
        font-size: 3em;
        opacity: 0.2;
    }

    .quotee-name {
        font-size: 0.8em;
    }
}

