.upper-page-supporting-block {
  @extend .supporting-content;
  padding: 3em 4em;
  margin: 3em -4em;

  > h3:first-child , > .h3-font:first-child {
    margin-top: 0;
  }

  > .prominent-button:last-child {
    margin-bottom: 0;

    a {
      margin-bottom: 0;
    }
  }
}
