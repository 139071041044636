@each $configName, $colourConfig in $colourConfigs {
  pattern#subtle-hatched-#{"" + $configName} path {
    stroke: map_get($colourConfig, subtle);
  }
}

$map-height: 40vh;

body .jvectormap-tip {
  background: #fff;
  color: $dark-grey;
  font-family: $font-family-headings;
  font-size: $base-font-size * 1.2;
  font-weight: bold;
  border-radius: 0;
  box-shadow: 0 0 7px rgba(0,0,0,0.2);
  border: none;
  padding: 0.5ex 1em;
  margin-top: -20px;
  cursor: pointer;

  &:empty {
    display: none !important;
  }
}

.map-container {
  display: none;
  min-height: 200px;
  height: 200px;
  height: $map-height;
  max-height: $map-height;
  position: relative;

  .alignment-helper {
    height: 0;
    position: relative;
    overflow: visible;
    z-index: 1;
  }

  .map {
    z-index: 0;
    height: 100%;
    position: relative;
  }

  .buttons {
    display: none;
  }

  .zoom-in, .zoom-out {
    position: absolute;
    left: 0;
  }

  .zoom-in {
    top: $hit-box-height;
    background: $dark-grey;

    svg {
      fill: #fff;
    }

    &:hover, &:focus {
      background: #000;
    }
  }

  .zoom-out {
    top: ($hit-box-height * 2);
    background: #fff;

    svg {
      fill: $dark-grey;
    }

    &:hover, &:focus {
      background: $light-grey;
    }
  }

  &.interactive {
    .buttons {
      display: block;
    }
  }

  // Map colours

  .jvectormap-region {
    fill-opacity: 1;
    transition: opacity 0.1s linear;

    @each $configName, $colourConfig in $colourConfigs {
      .#{"" + $configName} {
        fill: map-get($colourConfig, intense);
        stroke: map-get($colourConfig, intense);

        &.not-highlighted {
          opacity: 0.2;
        }

        &.partner {
          fill: url(#subtle-hatched- + "" + $configName) map-get($colourConfig, subtle);
          stroke: url(#subtle-hatched- + "" + $configName) map-get($colourConfig, subtle);
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}